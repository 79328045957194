.header-section {
  width: 100%;    
  margin-bottom: 6vw; 
}

.main-container{
  width: 100%;
}

.form-container {
  width: 100%;
  padding:2vh var(--horizontal-padding) 10vh var(--horizontal-padding);
}

.left-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start; 
  flex-wrap: wrap;
  width: 100%;
  min-width: 330px;
  flex: 1;
}

@media screen and (min-width: 770px) {
  .left-container {
    padding-right:15px;
  }
}

@media screen and (max-width: 600px) {
  .left-container label{
    font-size:14px;
  }
}

.right-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end; 
  flex-wrap: wrap;
  width: 100%;
  min-width: 330px;
  flex: 1;
  margin-left: 5vw;
}

@media screen and (max-width: 850px) {
  .right-container {
    margin: 40px 0px 0px 0px;
  }
}

@media screen and (max-width: 600px) {
  .right-container label{
    font-size:14px;
  }
}



.my-dropdown-slidedown{
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;  
}

.form-control {
  box-sizing: border-box;
  height: 4vh;
  width: 100%;
  font-size: 16px;
  margin-bottom: 1vw;
  border: none;
  border-bottom: 1px solid #9d9d9d; 
  padding: 0 1vh;
  font-family:'Geologica';
  outline:none;
}

input.form-control:focus-visible {
  outline: 0;
  border-bottom: 1px solid var(--black);
}

.info-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: var(--horizontal-padding);
  padding-right: var(--horizontal-padding); 
  margin-bottom: 2vw;
}

.info-icon {
  font-size: var(--small-font-size);
  margin-right: 0.2vw;
}

.info-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start ;
  margin-bottom: 0.5vw;
  font-size: var(--small-font-size);
  color: var(--black); 
  font-weight: 400;  
}

.info-text svg {
  margin-right: 10px;
}

.info-line{
  width: 100%;
  border: none;
  border-bottom: 1px solid #9d9d9d; 
}

.checkout-login {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: flex-start ;
  margin-bottom: 4vw;
  font-size: var(--small-font-size);
  color: var(--black);
  padding-left: var(--horizontal-padding);
  font-weight: 400;
}

.here-word {
  text-decoration: underline;
  font-weight: 500;
}

.here-word:hover {
  color: var(--main-red);
}

.checkbox-container {
  display: flex;
  justify-content: flex-start;
  align-items: center; 
  margin: 1vw 20px 0.5vw 0;
}

.form-check-label{  
  font-size: var(--small-font-size);
  font-weight: 650;
  margin-left:5px;
}

.disable-paypal-buttons {
    pointer-events: none;
    opacity: 0.7;
}

.payment-container{
  display: flex;
  flex-direction: column; 
  margin-top: 4vh;
  width: 70vw;
}

.checkout-order-table-title{
  display: flex; 
  width: 100%; 
  align-items: baseline; 
  justify-content: left;
  font-size: var(--medium-font-size);
  font-weight: 650;
  margin-bottom: 2vw;
}

@media screen and (max-width: 770px) {
  .checkout-order-table-title{
    font-size: var(--xlarge-font-size);
    margin-bottom:25px;
  }
}

.checkout-order-table {
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: var(--footer);
  border-radius: 5px;
  font-size: calc(12px + 0.3vw);
  padding: 1vw;
  background-color: var(--footer);
}

.checkout-order-table-header {
  width: 100%; 
  display: flex;
  align-items: baseline; 
  justify-content: space-between;
  color: var(--black);
  font-size: var(--small-font-size);
  font-weight: 650;
  padding-bottom: 1vw;
  border-bottom: 1px solid var(--black);
  margin-bottom: 1vw;
}

@media screen and (max-width: 670px) {
  .checkout-order-table-header{
    font-size: var(--medium-font-size);
  }

}


.sub-total-color {
  color: var(--grey-dark);
}

.checkout-order-table-component {
  width: 100%;  
  display: flex;  
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 0.5vw;
  border-bottom: 1px solid var(--grey-dark);
  margin-bottom: 1vw;
}

.component-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: var(--small-font-size);
  font-weight: 650;  
}

.component-data {
  width: 75%;
  font-size: var(--xsmall-font-size);
}

.payment-title {
  margin: 2vw 0 2vw 0;
  font-size: var(--small-font-size);
  font-weight: 650;
}

@media screen and (max-width: 670px) {
.payment-title {
  font-size: var(--medium-font-size);
}
}

.checkout-order-table-paymentType {
  display: flex; 
  font-size: var(--small-font-size);
  font-weight: 650;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1vw;
}

.paypal-error{
    font-size: var(--xsmall-font-size);
    color: var(--main-red);
    font-weight: 600;  
    margin-left: 1vw;  
}

.checkout-order-table-acceptTerms {
  width: 100%; 
  display: flex;
  align-items: center; 
  justify-content: flex-start;
  font-size: var(--xsmall-font-size);
  margin: 2vw 0;
}

.checkout-order-table-submit {   
    width: 100%;
    display: flex;    
    align-items: center;   
    justify-content: flex-start; 
    margin-top: 30px;
}

.checkout-order-table-submit button {
  margin: 0;
}

.checkout-order-table-footer {
  width: 100%;
}

.banks-container{
    width: 100%;
    padding: 0.5vw;    
    background-color: #f0eeee;
    border-bottom: 1px solid grey;
  }

.bank-table-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 98%;
  border: 1px solid grey;
  margin-top: 0.5vw;
}

.bank-table, th{ 
 text-align: start;
  
}

.error-message{
  color:var(--red);
  font-size: 0.8em;
  font-weight: 600;
  margin-top: -1vw; 
}

.checkout-paypal {
    width: 100%;      
    display: flex;    
    align-items: center;    
    justify-content: center;    
    flex-direction: column;
    margin-top: 2vh;
  }

 

  .row-container {
    display:flex;
    flex-direction: row;    
    width: 100%; 
    justify-content: 'space-between';
  }

  .three-quarters-container{    
    flex: 3 75%;    
  }

  .one-quarter-container-right{    
    flex: 1 25%;
    margin-left: 1vw;
  }

  .two-quarters-container {    
    flex: 1 50%; 
  }  

  .two-quarters-container-right {    
    flex: 1 50%; 
    margin-left: 1vw;
  }

  @media screen and (max-width: 850px) {
    .checkout-body-container {    
      flex-direction: column;
      margin-bottom: 10px;
    }
    .checkout-order-table-paymentType {
      height: 3vh;
    }
    .checkout-body-container {
      padding: 0; 
    }
    .checkout-order-table-component {     
      height: 6vh;
    }
  }

  .checkout-paypal-buttons {
    width: 50vw;
    height: 25vh;   
  }

  .registered-customer{
    margin-bottom: 4vh;
  }

  .afm-search-button {
    background-color: #e3e3e3;
    border-radius: 50%;
    color: #000;
    height: 35px;
    margin-left: 0px;
    margin-top: -7px;
    width: 35px;
    padding: 4px;
  }
  .addr-object {
    width: 100%;
    height: 8vh;  
    display: flex;    
    align-items: center;  
    justify-content: flex-end;
    background-color: var(--footer);
    border-radius: 5px;
    font-size: calc(12px + 0.3vw);
    margin-bottom: 20px;  
    color: var(--black);
  }
  .address-object-active {
    background-color: var(--accentColor);
    color: var(--white);
  }
  .address-object-radio {  
    display: flex;
    align-items: center;    
    justify-content: flex-start;
    margin-left: 1vw; 
  }
  .addresss-label {
    color: grey;    
  }

  .active-address-label {
    color: whitesmoke;
  }
  .address-object-info-container {    
    display: flex;    
    flex:6;   
    align-items: center;   
    justify-content: flex-start;
  }
  .address-object-end {   
    display: flex;    
    flex:1; 
    align-items: center;
    justify-content: flex-end;    
  }  

  .address-object-active {
    background-color: var(--accentColor);
    color: var(--white);
  }
  
  .address-object-button {
    display: flex;
    flex-direction: column;
    margin: 0 1vw;
    background-color: var(--footer);    
  }
  .button-active {
    background-color: var(--accentColor);
  }
  .company-addresses-container { 
    display: flex;  
    align-items: center;  
    justify-content: center;  
    flex-direction: column;
    width: 100%;   
  }

  .company-address-container {
    overflow: auto;     
    width: 100%;
  }