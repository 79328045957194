.footer{
    display: flex;
    width: 100%;
    background-color: var(--footer);   
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 3vw var(--horizontal-padding);    
    position: relative;  
    flex-wrap: wrap;
    border-top: 3px solid var(--main-red-aux);
  }

  @media screen and (max-width: 768px){
    .footer {
      padding: 6vw var(--horizontal-padding);
    }
  }

  .footerLogo {
    display: flex;   
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    margin-bottom:10px;
  }
  
  .logo {
    width: 250px;
    margin-right: 1vw;
  }

  .logo-square {
    width: 150px;  
  }

  .company-name {
    font-size: var(--xlarge-font-size);
    font-weight:600; 
    color: var(--main-red)  
  }

  .info-footer {
    display:flex;
    flex-direction: column;
  }

  @media screen and (max-width: 768px){
    .info-footer {
      width:100%;
  }
  }
  
  .section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media screen and (max-width: 768px){
    .section {
      margin-bottom:20px;
    }    
  }

  .section-50 {
    flex: 3;
  }

  @media screen and (max-width: 768px){
    .section-50 {
      flex: unset;
      width: 100%;
    }    
  }

  .section-25 {
    flex: 2.5;
    margin-left:4vw;
  }

  @media screen and (max-width: 768px){
    .section-25 {
      width:100%;
    }    
  }

  @media screen and (max-width: 768px){
    .section-first {
      margin-left:0px;
    }
  }

  @media screen and (max-width: 396px){
    .section-second {
      margin-left:0px;
    }
  }

  
  
  .footerSocial {
    display: flex; 
    align-items: center;
    justify-content: flex-start; 
    margin-bottom: 0.4vw;  
  }
  
  .footerCredits {
    padding: 5px;    
  }
  
  
  .footerMenuTitles {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 1vw;
  }
  
  .footerMenuText {
    color: black; 
    font-size: 24px;
    margin-left: 5px;
    text-decoration: none;
  }
  
  .footerMenuText2 {
    color:black; 
    font-size: 16px;
    margin-left: 5px;
    text-decoration: none;
    margin-bottom: 0.4vw;
    white-space: nowrap;
  }

  .footerMenuText2:hover {
    color: var(--main-red); 
  }

  .terms-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: var(--white);
    align-items: center;
    justify-content: center;   
    text-align: left;    
    padding: 0 var(--horizontal-padding);
  }  

  .terms-text {
    padding: 5vh 0;
    width: 100%;
  }

  .terms-text p {
    text-align: left;
  }

.footer-row{
    display: flex;
    align-items: start;
    flex-wrap: nowrap;  
    margin-bottom: 0.4vw;    
}

.block-element{
  display: inline;  
  color: #fff;
  margin-left: 5px;
}

.block-dots {
  display: inline;
  color: #fff;
  margin-left: 0.2vw;
  margin-right: 0.4vw;
}

.link-text{
  color:var(--black);
  font-weight: 500;
}

.link-text:hover{
  color: var(--main-red);
}

.footer-link-text {
  margin-left:5px;
}

.link-bringfood-text{
  color: var(--black); 
  margin-left: 1vw;
}

.link-bringfood-text:hover{
  color: var(--main-red);
}

.arrow-footer {
  position: relative;
  border-top: 1px solid var(--grey-dark);
  font-size: var(--basic-font-size);
  width: 100%;
  background-color: var(--header);
}

.arrow-up {
  width: 30px;
  height: 30px;
  padding: 15px;
  top: -15px;
  left: 51%;
  border-radius: 50%;
  background-color: var(--grey-dark); 
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
}

.arrow-up:hover {
  background-color:#c2c2c2;
  cursor: pointer;
}

.arrow-icon {
   color: #fff;
}

.arrow-appdate{
  height: 1vw; 
  margin-left: 0.1vw;
}

.header, .copyright {
  background-color: var(--header);
  padding: 0.4vw var(--horizontal-padding);
  display: flex; 
  flex-direction: row; 
  font-size: var(--basic-font-size);
  color: black;
  width: 100%;
  flex-wrap: wrap;
}

@media screen and (min-width: 768px){
.copyright-bar-el-upper {
  padding:10px 0px;
}
}

@media screen and (min-width: 992px){
  .top-bar-el-upper {
    min-width:250px;
}
}

@media screen and (min-width: 768px){
.top-bar-el-upper {
  min-width:200px;
}
}

@media screen and (max-width: 600px){
.top-bar-el-upper, .copyright-bar-el-upper {font-size:13px;}
.copyright-bar-el-upper {margin-bottom:5px;}
}

@media screen and (max-width: 650px)  {
  .header, .copyright {padding: 1.4vw var(--horizontal-padding);justify-content:space-evenly}
}

.header-left {
  flex:1
}

.header-sub {
  flex:1
}

.header-location-element {
  margin-left: 2vw;
}

.header-text-item, .copyright-text-item {
  margin-left: 5px;
  color: black;
  text-decoration: none;  
  white-space: nowrap;
}

.header-text-item:hover, .copyright-text-item:hover {
  color: var(--main-red)
}

.footer-text-item {
color: #000;
margin-left: 10px;
text-decoration: none;
white-space: nowrap;
}

.footer-text-item:hover {
  color: var(--main-red)
}

.footer-store-icons{
  display: flex;
  text-align: center;
  justify-content: flex-start;   
}

.footer-icon {    
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 1vw;
}

.footer-icon:hover{
  opacity: 0.6;
}

.social-icon {
  margin-left: 0.1vw;
  color: black;
  text-decoration: none;
  margin-bottom: -5px;  
}

.fb{ 
  color: var(--facebook-blue)
}

.social-icon:hover{
  color: var(--main-red)

}
.line{
  width: 100%;
  border-top: 1px solid #9d9d9d;
  margin-bottom: 1vw;
}

@media screen and (max-width: 400px)  {
  .line{
    width: 20%;
    border-top: 2px solid #9d9d9d;
  }
}

.footer-section-header {
  display: flex;
  align-items: baseline;
  width: 100%;
  font-size: var(--medium-font-size);
  font-weight: 650;
  margin: 0 0 1vw 0;
}

@media screen and (max-width: 992px) {
  .footer-section-header {
    font-size: var(--xlarge-font-size);
  }
}

@media screen and (max-width: 992px) {
  .header, .copyright {
    font-size: calc(9px + 0.4vw);
  }
  .header-location-element {
    margin-left: 0px;
  }

  .logo {
    width: 170px;    
  }

  .logo-square {
    width: 130px;  
  }
  
  .footer-store-icons {
    display: none;
  }

  .arrow-footer {
    padding-bottom: 10px;
  }

}

@media screen and (max-width: 600px) {
  .logo-square {
    width:130px;
  }
}

