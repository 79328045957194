.account-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;   
}

.header-container {
    width: 100%;
    height: var(--carousel-height);
    display: flex;    
    align-items: center;    
    justify-content: center;  
    font-size: var(--xlarge-font-size);
    font-weight: 650;
    color: var(--black);
    background-color: var(--footer);
}

@media screen and (max-width: 1024px) {
  .header-container {
    height: var(--carousel-height);
  }
}

.header-container .cart-title {
    width: 100%;
    height: 10vh; 
    display: flex;    
    align-items: center;  
    justify-content: flex-start;
    border-bottom: var(--black);
    border-style: solid;
    border-width: 2px;
    border-top: none;
    border-left: none;
    border-right: none;
    margin-bottom: 3vh;
    font-weight: bold;
    font-size: calc(1.5em + 0.8vw);
  }



.rated-card .style-comment-section{
    display: flex;
}

.rated-card .style-comment-section textarea {
    background-color: rgb(255, 252, 224); 
    width: 100%; 
    border-radius: 5px;
    padding: 5px 10px;
}

.rated-card .style-comment-section textarea:focus {
    background-color: rgb(255, 253, 239); 
    border: 1px solid rgb(209, 209, 209);
    outline: none;
}

.rated-card .comment-card-commentText {
    width: 100%;
}

.handle-comments-button {
    display: flex;
    align-items: center;
    margin-left: 1px;
}

.handle-comments-button div {
    border: none;
    outline: none;
    background-color: var(--off-white);
    cursor: pointer;
}

.handle-comments-button .comment-edit {
    outline: none;
    color: rgb(117, 95, 77);
}

.handle-comments-button .comment-save {
    outline: none;
    color: rgb(48, 173, 48);
}

.handle-comments-button .comment-delete {
    outline: none;
    color: rgb(228, 20, 20);
}

.handle-comments-button div svg{
    border: none;
    outline: none;
    font-size: 1.7em;
}

.account-board {
    width: 100%;  
    min-height: 40vh;    
    margin-top: 5vh;
    margin-bottom: 10vh;
    padding: 0 var(--horizontal-padding)   
}

@media screen and (max-width: 768px){
    .account-board {
       width:100%;     
    } 
}

.account-board-header {
  background-color: var(--white);
  width: 100%; 
  border-bottom:1px solid #9d9d9d;      
  display: flex; 
  align-items: flex-end;  
  justify-content: space-evenly;
  flex-wrap: wrap;   
}

.account-header-button {  
  padding:0 10px 10px; 
  background-color: var(--white);
  color: var(--black);  
  font-size: var(--small-font-size);
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 10px;
}

.account-header-button:hover {
    color: var(--main-red);
    cursor:pointer;
}

.selected-button {
    color: var(--accentColor);  
}


.changePassTitle h4{
    font-size: calc(18px + 0.5vw)
}

@media screen and (min-width: 1200px){
    .account-board-header h4,
    .changePassTitle h4{
        font-size: calc(24px)
    }
}

.icon-font-size{
    transform: scale(1.4);  
}

.account-board-body {
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.upper-section { 
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row-reverse;
    flex-wrap:wrap;
  }

.profile-save-area {
    padding: 10px 0px 20px 0px;
}

  .profile-save-area button {
    margin-left: 0;
}

  .change-password-container {  
    display: flex;  
    align-items: center; 
    justify-content: center;   
    justify-content: flex-start;

  }
  
  .change-password-container .changePassTitle { 
    display: flex;
    justify-content: flex-start;
    align-items: center;   
    font-size: var(--small-font-size);
  }  

  .changePassTitle span {
    margin-left:10px;
  }

  .changePassTitle svg {
    width: 0.7em;
  }
 
  .profile-data-form {
    height: 300px; 
    display: flex;  
    flex: 1;
    align-items: flex-start; 
    justify-content: space-between;  
    flex-direction: column;
    background-color:  var(--white);
    margin: 3vh 0;
    min-width: 300px;
  }
  
  .profile-data-form input {
    font-family: 'Geologica';
  }

  .camera-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    flex: 1;
  }

  @media screen and (max-width: 561px){
    .camera-container {
      align-items:flex-start;
    }

  }

  .change-password-popup {
    height: 300px; 
    width: 50%;
    display: flex;  
    align-items: flex-start; 
    justify-content: space-between;  
    flex-direction: column;
    background-color:  var(--white);
    margin: 3vh 0;
  }

  @media screen and (max-width: 992px){
    .change-password-popup {
      width:100%;
    }
  }

  .change-password-popup input {
    font-family: 'Geologica';
  }

  .wishlist-object {
    width: 100%;
    height: 8vh;  
    display: flex;    
    align-items: center;  
    justify-content: space-between;
    background-color: var(--footer);
    border-radius: 5px;
    font-size: calc(12px + 0.3vw);
    margin-bottom: 20px;  
    color: var(--black);
  }
  
  .wishlist-object button {
    border: none;
    background-color: var(--footer);
    outline: none !important;
  }
  
  .wishlist-object img {
    width: 6vh;
    height: 6vh;
    border-radius: 5px;
  }

  .addresss-label {
    color: grey;    
  }

  .active-address-label {
    color: whitesmoke;
  }

  .address-object {
    width: 100%;
    height: 8vh;  
    display: flex;    
    align-items: center;  
    justify-content: space-between;
    background-color: var(--footer);
    border-radius: 5px;
    font-size: calc(12px + 0.3vw);
    margin-bottom: 20px;  
    color: var(--black);
  } 
  
  .address-object-radio {  
    display: flex;
    flex: 2;   
    align-items: center;    
    justify-content: flex-start;
    margin-left: 1vw;    
    margin-bottom: 2px;   
  }

  .address-object-radio label span {
    font-family: 'Geologica';
  }
  
  .address-object-info-container {    
    display: flex;    
    flex:6;   
    align-items: center;   
    justify-content: flex-start;
  }

  .address-object-end {   
    display: flex;    
    flex:1; 
    align-items: center;
    justify-content: flex-start;    
  }  

  .address-object-active {
    background-color: var(--accentColor);
    color: var(--white);
  }
  
  .address-object-button {
    display: flex;
    flex-direction: column;
    margin: 0 1vw;
    background-color: var(--footer);    
  }

  .button-active {
    background-color: var(--accentColor);
  }
  
  .address-object img {
    width: 6vh;
    height: 6vh;
    border-radius: 5px;
  } 

  .history-container { 
    display: flex;  
    align-items: center;  
    justify-content: center;  
    flex-direction: column;
    width: 100%;   
  }

  .history-object-container {
    overflow: auto;
    min-height: 40vh;   
    width: 100%;
  }

  .history-object { 
    width: 100%;
    min-height: 10vh;
    background-color: var(--footer);
    margin-bottom: 1vw;
    border-radius: 10px;
    padding: 0.5vw 1vw;
    color: var(--black);
    font-size: var(--xsmall-font-size);
  }
  
  .history-object-topHeader {        
    display: flex;   
    justify-content: space-between;   
    align-items: baseline;
    border-bottom: 1px solid var(--black);
    padding-bottom: 0.5vw;
  }

  .order-number {
    font-size: var(--small-font-size);
    font-weight: 650;
  }

  .status-line {
    font-size: var(--small-font-size);    
  }
  
  .history-object-body-container {
    height: auto;
    width: 100%;     
    display: flex;   
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 0.5vw;
  }
  
  .history-object-body {    
    width: 100%;   
    display: flex;   
    justify-content: space-between;    
    align-items: baseline;    
  }
  
  .history-object-footer {
    padding-top: 0.5vw;
    margin-top: 0.5vw;
    display: flex;   
    justify-content: space-between;  
    align-items: baseline;
    font-weight: 650;
    border-top: 1px solid var(--black);   
  }
  
  .history-object-link {   
    color: var(--black); 
  }
  
  .history-object-link :hover { 
    color: var(--main-red); 
    text-decoration: underline;  
  }

  .settings-margin{
    margin-top: 2vw;
  }

  .settings-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding:0 1vw;
  }

  .settings-sub-container {
    display: flex;   
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var( --footer);
    padding-top: 7vh;
  }

  .settings-sub-container-about {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var( --footer);
  }

  .empty-component-text{
    color: var(--cool-grey);
    text-align: center;
    font-size: calc(16px + 0.8vw);
    font-weight: 400;
    margin-top: 6vw;
  }

  .delete-account-text {
    text-decoration: underline;
  }

  .delete-account-text:hover {
    color: var(--main-red);
  }