.top-bar-nav {  
  width:100%;
  display: flex;
  flex-flow: row nowrap; 
  align-items: center; 
  justify-content:space-between; 
  padding: 1.5vw var(--horizontal-padding);   
  box-shadow: rgba(0, 0, 0, 1) 0px 7px 5px -7px; 
}

.logoMenu { 
  background-color: var(--background);
  display: flex;
  flex-direction: row;
  flex:1;
  font-size: calc(10px + 2vmin); 
}

.logoView {
  display: flex;   
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}

.logo-img {
  width: 300px;
  margin-right: 1vw;
}

.logo-img-square {
  width: 150px;
}

.company-name-topbar {
  font-size: var(--large-font-size);
  font-weight:600; 
  color: var(--main-red)  
}

.genContainer {
  display: flex;
  color: var(--black);
  align-items: center;
  justify-content: center;  
  margin-right:5px;
}

.genContainer:hover {
  color: var(--main-red-aux);
}

a.genContainer {
  margin: 4px 0px;
}

.cart-container{
  display: flex;
  color: var(--black);
  align-items: center;
  justify-content: center; 
}

@media screen and (max-width: 768px){
.cart-container svg {
  height: 29px;
}
}

.cart-container:hover {
  color: var(--main-red-aux);
}

.login-link {
  font-size: var(--small-font-size);
  font-weight: 600;
  margin-right:10px;
}

.open-hours {margin-left: 5px;}

@media screen and (max-width: 600px){
  .login-link {display:none;}
}

.settingsContainer {
  display: flex;
  flex-direction: column;
  color: var(--black);
  align-items: flex-start;
  justify-content: flex-start; 
  margin-right: 0.2vw; 
}

.logoMenuView {
  display: flex;
  flex:1;
  color: var(--onBackground);
  align-items: center;
  justify-content: flex-end;  
}

.nav-nav-left, .nav-nav-fill, .nav-nav-right {
  display:flex;
  flex-flow: row nowrap; 
  align-items: center;  
}
  
.nav-nav-left{
  flex: none;
  margin-top: -5px; 
  justify-content: flex-start;
}

.nav-nav-right {
  flex: none;
  justify-content: flex-end;
}
  
.nav-nav-fill{  
  flex: auto;
  justify-content: center;
  flex-wrap:nowrap;
  margin: 0 5px;
}


.phone-p {  
  margin-left:2px;
  color: var(--main-text-color);
  font-size: 16px;
}  

.phone-number {
  display:inline;
} 

#btn-menu {
  left: auto !important;
  right: 0px;
}  

.nav-SignIn {   
  border: none;    
  display: flex;   
  align-items: center;    
  justify-content: center;    
  flex-direction: row;
} 

.nav-button-SignIn {  
  outline: none !important; 
  border: none;  
  font-size: 16px;
  background-color: transparent;
  color: var(--main-text-color);  
  display: flex;
  flex-wrap: nowrap;   
  align-items: center;    
  justify-content: center;   
}

.nav-button-SignIn svg {
  height: 37px;
  margin-left: 5px;
}

.button-SignIn p {
  font-size: 16px;
  margin-bottom: 1px;
}

.calendar-icon {  
  color: var(--main-text-color)
}

.nav-number{
    text-align: center;
    font-weight: 400;
    position: absolute;
    left: 14px;
    background-color: var(--main-red-aux);
    border-radius: 20px;
    width: 19px;
    height: 19px;
    line-height: 19px;
    font-size: 14px;
    color: white;
    margin-left: var(--horizontal-padding);
}

.undecorated-link{
  text-decoration: none;  
  padding: 0;
  font-family: 'Geologica';
}

.undecorated-link:hover{
  color: var(--main-red);;
}

.cart-container{
  margin-left: 1vw;
}


  .account-btn {
    outline: none !important;
    border: none;
    background-color:  #131921;
    color: var(--main-text-color);
    font-size: 18px;
    margin-left: 5px;
  }  

  .language-styling{
    font-size: 16px;    
  }  

  .collapse-list-item{
    color:#131921;
    font-size: calc(10px + 0.6vmin);
    padding: 10px 5px;
  }

  .collapse-list-item:hover{
    background-color: #d0d0d0;    
  }

  .collapse-list-item:active{
    background-color: var(--amazon-orange-color);    
  }

 .search-collapse{
    width:100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 100;
    top: 42px;   
    background-color: var(--footer) ;    
    border-radius: 4px;
    padding: 0.5vw;  
    max-height: 20vw;
    overflow: auto;   
  }

  .search-container{   
    width:40%;
    display: flex;
    justify-content: space-between;
    align-items: center;  
    border: 1px solid var(--grey-background);
    border-radius : 6px;
    margin-left: 10px;
  }

  .search-wrapper{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .search-input {
    border: none; 
    height: 40px;
    flex: 1 1 auto;    
    width: 100%;
    font-size: 16px;   
    border-top-left-radius : 6px;
    border-bottom-left-radius: 6px;
    outline:none;
    font-family:'Geologica';
  }

  .search-input::placeholder{
    padding: 0.4vw;
    font-size: 16px;
  }

  .search-menu-button{   
    background-color:  var(--background);
    border: none;
    color: var(--main-text-color);
    padding: 7px 8px;    
    text-decoration: none;    
    font-size: 16px;
    cursor: pointer;
  }

  .search-toggle{
    background-color: transparent;
    color: var(--black); 
    cursor: pointer;
    margin-left: 1vw;  
  } 

  .search-toggle:hover{
    color: var(--main-red-aux);
  }

  .search-button{    
    color: var(--main-text-color);
    background: var(--background);
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 0px;
    border-right: 1px solid #9d9d9d;
    border-left: 1px solid #9d9d9d;
  } 

  .mic-button{    
    padding-left: 12px;
    padding-right: 12px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  } 

 .search-button:hover, .mic-button:hover {
    color: var(--white);
    background: var(--main-red);
  }
  
  .search-dropdown{
    top: 10px;
  } 
  

  .select-language {
    margin: 0 0 0 2vw;    
   
  }

.navbar-collapse.show {
    padding: 15px;
    background-color: rgb(66, 63, 63);
}

.navbar-collapse.show .nav-item{
  padding-top: 10px;  
}

navbar-brand{
  text-decoration: none;
}


.hamburger-show{
  display:none;
} 

.responsive-area{
  display:none;
}

@media screen and (max-width: 992px)  {
  .search-container{   
    width:100%;
    margin-left:0px;
  }
}
 
@media screen and (max-width: 992px)  {
  .hamburger-show{
    display:flex;    
  } 
  .phone-number, .genContainer, .search-toggle, .nav-nav-fill, .nav-SignIn:not(.cart-container){
    display:none;     
  }  
  .cart-container{
     margin-right: 0;
  } 

  .top-bar-nav {
    padding: 1vw var(--horizontal-padding);
  }

  .divider {
    width: 98vw;
  }

  .responsive-area{
    display: flex; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;     
    background-color: #ffffff;  
    padding: 0px 0px 22px 0px;
    box-shadow: 0 7px 5px -7px #000;
  }

  .logo-img {    
    width: 225px;    
  }

  .logo-img-square {    
    width: 115px;    
  }

}

@media screen and (max-width: 650px)  {
  .logo-img {
    width: 170px;  
    padding:10px 0px;  
  }

  .logo-img-square {
    width: 75px;    
  }
}

.middle-container{
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.responsive-element { 
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  width: 100%;
  padding: 0.4vw var(--horizontal-padding);
  width: 96%;
  width: 100%;
  margin: 3px 0px;
}

.lang-pointer {
  cursor: pointer;
  font-family: 'Geologica';
}

.checkout-button{ 
  color:var(--main-text-color);
  text-decoration: none;   
} 

.swipe-button{
  display: flex;  
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 24px;
  margin-top: 2vw ;
}

.add-margin{
  margin-left: 1vw ;
}

.search-bar {  
  width: 20%;
}

.divider {
  width: calc(100% - 2*var(--horizontal-padding));
  border-top: 2px solid #9d9d9d;
}