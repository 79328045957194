.mexican {   
    --header: #acf983;
    --footer: rgb(235, 236, 236);
    --grey-dark: rgb(114, 113, 113);

    --main-red: rgb(227, 28, 35);
    --main-red-h: 358;
    --main-red-s: 78%;
    --main-red-l: 50%;    

    --main-red-aux:  #01be64;
    --main-red-aux-dark: #046838; 


    --primaryColor: #4f86f7;
    --primaryColorDark: #115bf4;
    --primaryColorLight: #9dbcfb;
    --onPrimaryColor: #fff;

    --header-color: #3c9324;
    --white-theme: #F1FAEE;
    --blue-light-theme: #A8DADC;
    --blue-middle-theme: #457B9D;
    --blue-dark-theme: #1D3557; 
   
    --accentColor: hsl(151, 100%, 28%);
    --accentColor-h: 151;
    --accentColor-s: 100%;
    --accentColor-l: 28%;
    --onAccentColor: #fff;
    
    --secondaryColor: #83d076; 
    --onSecondaryColor: #fff;
    
    --tertiaryColor: #de5246; 
    --onTertiaryColor: #fff;
  
    --statusBarColor: #eeeeee;
   
    --primaryGradientColor: #4f86f7;
    --secondaryGradinetColor: #47b8ff;
   
    --overlayColor: #9dbcfb;
  
    --primaryText: rgba(0; 0; 0; 0.87);
    --secondaryText: rgba(0; 0; 0; 0.54);
    --disabledText: rgba(0; 0; 0; 0.38);
   
    --background: #fff;
    --onBackground: #212121;
    --surface: #fff;
    --onSurface: #757575;
    --error: #cd040b;
    --onError: #fff;
    --black: #000;
    --white: hsl(0, 0%, 100%);
    --off-white: #faf6ce;
    --main-text-color: #f1ebff;
    --link-color: hsl(36, 100%, 50%);
    --title-color: hsl(36, 100%, 45%);
    --new-label-color: rgb(0, 145, 75);
    --discount-label-color: #ffa400;
    --hot-label-color: #b90039;
    --sold-out-label-color: #eb3434;

    --red: #b90039;
    --cool-grey: #9BA0BC;
    --button-default: #7e7f83; 
    --main-text-color: #212121;
    --facebook-blue: hsl(220, 46%, 48%);
    --facebook-blue-h: 220;
    --facebook-blue-s: 46%;
    --facebook-blue-l: 48%;
    --settings-text: #393e46;
    --grey-background: #dedede;
    --radio-label: #045dc2;
    --image-hover: #ffecce;
    --amazon_orange-h: 36; 
    --amazon_orange-s: 100%;
    --amazon_orange-l: 50%;
    --selected-setting: hsl(var(--amazon_orange-h), var(--amazon_orange-s), calc(var(--amazon_orange-l)*0.4));
    --hovered-setting: hsl(var(--amazon_orange-h), var(--amazon_orange-s), calc(var(--amazon_orange-l)*1.4));
 }
 
