.cart-body {
  width: 90%;
  min-height: 50vh;    
  display: flex;  
  flex-direction: column;    
  justify-content: flex-start;
  align-items: center; 
}
  
.cart-body .empty-cart {   
  display: flex;
  height: 100%;
  width: 90%;
  background-image: url("../../img/emptyCart_yellow.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 400px;
  height: 400px;
}
  
.cart-title {
  width: 90%;
  height: 10vh; 
  display: flex; 
  align-items: center;
  border-bottom: #ff9900;
  border-style: solid;
  border-width: 2px;
  border-top: none;
  border-left: none;
  border-right: none;
  margin-bottom: 3vh;
  font-weight: bold;
  font-size: calc(1.5em + 0.8vw);
}

.cartIndicator {
  display: flex;
  width: 24px; 
  height: 20px; 
  background-color: var(--main-red-aux); 
  align-items: center; 
  justify-content: center; 
  border-radius: 15px; 
  margin-left: -15px;  
  margin-top: -10px;
}

.table-container { 
  width: 100%; 
  display: flex;  
  margin: 3vw 0; 
  flex-wrap: wrap;  
  padding: 0 var(--horizontal-padding);
  flex-wrap: wrap;
}

.table-body{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 6;
}

.section-header {
  display: flex;
  align-items: baseline;
  width: 100%;
  font-size: var(--large-font-size);
  font-weight: 650;
  margin: 1vw 0;
}

.section-line {
  display: flex;
  flex: 1;
  border-bottom: 1px solid var(--black);
  margin-left: 2vw;
}

.table-header { 
  height: 4vh;
  background-color: #c3c3c3;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-width: 3px;
  border-bottom-color: #d9d9d9;
  border-bottom-style: solid;   
  display: flex;   
  align-items: center;    
  font-size: calc(0.8em + 0.8vw);
  padding-top: 0.5vh; 
  color:  var(--white);
}

.header-icons {  
  display: flex; 
  flex: 3;
  align-items: center;    
  justify-content: flex-start;
} 

.header-element{
  display: flex;
  flex: 2;
  align-items: center; 
  justify-content: center;
}

.table-footer { 
  display: flex;
  align-items: center;  
  margin-top: 4vw;
}

.coupon-caption {
  font-size: var(--smedium-font-size);
  font-weight: 650;
  margin-right: 10px;
}

.coupon-error-message {
  font-size: var(--smedium-font-size);
  font-weight: 650;
  margin-right: 10px;
  color: var(--main-red);
  margin-top:5px;
}

.coupon-input-container {
  position: relative; 
}

.coupon-input { 
  border-radius: 5px;
  width: 240px;  
  height: 36px;
  font-size: var(--small-font-size);
  font-family: 'Geologica';
}
.coupon-button{
  position: absolute;
  top: 0;
  right: 0;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center; 
  border-left: 1px solid #9d9d9d;
  padding: 0 5px;  
}

.cart-total-table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 4;  
  padding-left: 4vw; 
  min-width: 400px;
}

@media screen and (max-width: 815px){
  .cart-total-table{
    padding-left:0;
  }
}

@media screen and (max-width: 768px){
  .table-container {
    padding: 0.5vw;
  }
  .cart-total-table{
    padding: 0.4vw var(--horizontal-padding);
    min-width:unset;  
  }
  .table-body {
    padding: 0.4vw var(--horizontal-padding);
  }
} 
  
  .cart-total-body {    
    display: flex;  
    flex-direction: column;
    padding-bottom: 1vw;    
    border-bottom: 1px solid var(--black);
    margin-bottom: 1vw;
  }

  .totals-line {
    display: flex;
    font-size: var(--smedium-font-size);
    font-weight: 600;
    margin-top: 1vw;
  }
  
 .cart-total-footer {      
    display: flex; 
    flex-direction: column; 
    align-items: center;  
    justify-content: center; 
    padding: 3vw 1vw;    
  }

  .cart-footer-items {
    font-size: calc(0.6em + 0.6vw);
    font-weight: 700;
  }

  .cartProductCard { 
    margin-top: 2vw;
    width: 100%; 
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #ffffff;
    padding-bottom: 1vw;
    border-bottom: 1px solid var(--black);  
  }
  
  .favoriteProductCard { 
    position:relative; 
    display: flex;
    flex-direction: column; 
    justify-content: space-between; 
    width: 400px; 
    height: 280px;
    box-shadow: 0 2px 12px rgba(0,0,0,0.25), 0 2px 2px rgba(0,0,0,0.22);  
    background-color: #ffffff;
    margin: 2px;
  }

  .favorite-body{
    display:flex;
    flex: 5;
  }
 
  .cartProductCardPic {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: calc(120px + 6vw);
    height: calc(80px + 4vw);
  }

  .cartProductCardPic:hover {
    cursor:pointer;
  }

  .details-container {
    display: flex;
    flex: 1;
  }
  
  .cartProductCardDetails {
    flex:3;  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;    
  }

  .cart-product-title {
    font-size: var(--smedium-font-size);
    font-weight: 600;
    margin: 0 0 1vw 1vw; 
  }

  .attributeDetails {
    display: flex;
    margin: 0 1vw;
  }

  .favorite-red-star {
    color: red;    
  }

  .mandatory-attributes-container{
    display: flex;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    font-size: var(--smedium-font-size);
  }
  .mandatory-attributes-container::-webkit-scrollbar {
    height: 4px;  
  }
  /* Track */
  .mandatory-attributes-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
 
}
 
/* Handle */
.mandatory-attributes-container::-webkit-scrollbar-thumb {
  background: var(--main-red-aux); 
 
}

/* Handle on hover */
.mandatory-attributes-container::-webkit-scrollbar-thumb:hover {
  background: var(--main-red-aux-dark);
}

@media (min-width: 1200px) {
  .mandatory-attributes-container {
    font-size: var(--smedium-font-size);
  }
}

@media (min-width: 1500px) {
  .mandatory-attributes-container {
    font-size: var(--smallplus-font-size);
  }
}

  .list-items-attributeDetails {
    display: -webkit-inline-box;
    margin: 0 ;
    padding-right: 4px;   
    padding-bottom:3px;
  }

  @media (min-width: 992px) {
    .list-items-attributeDetails {
      margin: 10px 0 0 0 ;  
    }   
  }


  .cartProductCardDetails-Data {
    display: flex;
    flex: 3;     
    align-items:flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-right: 1vw;
    margin-left: 1vw;
    font-size: var(--small-font-size);
  }

  .cartProductCardDetails-P-Q-container {
    display: flex;    
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  .position-relative {
    position: relative;
  }

  .delete-button-container{
    position: absolute;
    top: 5px;
    right: 5px;   
  }
  
  .cartProductCardDetails-Price {
    display: flex;    
    height: 20%;     
    justify-content: flex-end;
    margin-right: 1vw;
    margin-bottom: 3vw;
    color: var(--black);
    font-size: var(--medium-font-size);
    font-weight: 600;
  }
  
  .cartProductCardDetails-Quantity {   
    display: flex;
    height: 80%;
    align-items: center;
    justify-content: flex-end; 
    margin-right: 1vw;  
    font-size: var(--medium-font-size); 
    font-weight: 600; 
  }
  
  
  .favoriteCard-CartButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex:1;
    width: 100%; 
    height: 35px;
    margin-bottom: 0.2vw;    
  }
 
  .couver-alert{  
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 400px; 
    border: 2px solid #9d9d9d;
    border-radius: 10px;
    padding: 10px;
    background-color: var(--white);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 924px) { 
    .couver-alert{   
      width: 300px;    
    }
  }

  .couver-title-text {
    display: flex;
    align-items: center;
    font-size: var(--medium-font-size);
    font-weight: 650;
    white-space: nowrap;
  }

  .couver-caption {
    font-size: var(--smedium-font-size);
    font-weight: 650;
    margin-right: 10px;
    color: var(--settings-text);
    margin-top:2vw;
  }

  .couver-input{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 120px;
    height: 36px;
    font-size: var(--small-font-size);
    background-color: var(--onSecondaryColor);
    outline:none;
  }
  
  .couver-button{
    position: absolute;
    top: 0;
    left: 120px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center; 
    border: 1px solid var(--black);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0 5px; 
    background-color: #008f4a;;
  }

  .couver-footer { 
    display: flex;
    align-items: center;  
    margin: 2vw 0;
  }

 
  