.messaging-title-container{
  background-color: var(--footer);
  color: var(--black);
  border-bottom: 4px solid var(--main-red);
  padding: 12px 50px;
}

.messaging-title{ 
  font-size: 28px;
  font-weight: 700;
  text-align: start;
  padding-bottom: 12px;
}
.messaging-sub-title{  
  font-size: 16px;
  font-weight: 400;
  text-align: start;
}
.content-container{
  height: 390px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.messages-list{
  display: flex;
  flex-direction: column; 
  margin: 2px;
  max-height: 300px;
  overflow: auto;
  align-items: center;
}

.message-line {
  display: flex;  
  margin-top: 6px;
}

.message-card{  
  width: 200px;  
  border-radius: 10px; 
  padding: 4px;  
  min-height: 40px;
}

.messaging-logo { 
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 10px; 
}
.message-card-admin{
  background-color: var(--white);
  color: var(--black);
  border: 1px solid var(--grey-dark);
}
.message-card-customer{
  width: 250px;  
  background-color:var(--main-red);
  color: var(--white);
}
.input-container{
  display: flex;
  margin: 0px;
  justify-content: center;
  align-items: center;  
  padding: 5px 0px 0px 15px;
}

.input-container label{
  font-family:'Geologica';
}

.messaging-btn-container{
  position: fixed;
  right: 20px;
  bottom: 100px;
  z-index:11;
}
.fab-button {
  background-color: var(--main-red);
  color: white;
}


@media screen and (max-width: 1024px) {
  .messaging-btn-container{
    bottom: 50px;
  }
}