.loyalty-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
  width: 90%;
}

.loyalty-title {
  font-size: 18px;
  font-weight: 600;
  margin-left: 1vw;
  margin-top: 2vw;
}

.loyalty-bar-container {
  position: relative;  
  height: 10px;
  width: 100%;
  margin-top: 45px;
  background-color: var(--cool-grey); 
}

.loyalty-way-point-container {
  position: absolute;  
  top: -10px;
  display: flex; 
}

.loyalty-way-icon-plus-caption {
  display: flex; 
  flex-direction: column;
}

.loyalty-way-point-icon { 
  display: flex;
  justify-content: center;
  align-items: center; 
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--facebook-blue);
}

.loyalty-way-point-bonus {
  color: var(--main-text-color);
  margin-top: 6px;
  font-size: 14px;
  font-weight: 600;
}

.loyalty-way-point-caption {
  color: var(--main-text-color);
  margin-bottom: 4px;
  text-align: center;
  font-size: 14px;
}

.animated-line {
  height: 10px;
  background-color: var(--discount-label-color); 
}

.pointer-container {
  position: absolute;  
  top: -30px;
  background-color: var(--discount-label-color);
  padding: 2px 4px;
  border: 1px solid var(--cool-grey);
  border-radius: 4px;
}

.days-till-end {
  margin-left: 1vw;
  color: var(--hot-label-color);
}

.promotion-text-container {
  width: 80px; 
  margin-top: -10px;
}

.promotion-text {
  font-size: 12px;
  color: var(--main-text-color); 
}