.add-address-container {
    width: 100%;
    height: 700px;   
    display: flex;    
    align-items: center;    
    justify-content: space-between;   
    flex-direction: column;  
    margin-top: 3vh; 
  }  
  
  .address-modal-header{
    width: 100%;
    display: flex;  
    flex-direction: row;
    align-items: flex-start;
  }
  
  .edit-address-title {
    display: flex;
    flex-direction: row;
    flex: 7;
    justify-content: center;
    padding-left: 5vw;
    font-size: var(--medium-font-size);
    font-weight: 650;
  }
  
  .edit-address-close-button {
    display: flex;
    flex-direction: row;
    flex: 1.2;
    border: none; 
    background-color:  var(--white); 
    outline: none !important;  
  }