.jade {
    --primaryColor: #00b970;
    --primaryColorDark: #00945a;
    --primaryColorLight: #00e78c;
    --onPrimaryColor: #fff;
    
    --accentColor: #0069b9;
    --onAccentColor: #fff;
 
    --secondaryColor: #b90039;
    --onSecondaryColor: #fff;
  
    --tertiaryColor: #ffa400;
    --onTertiaryColor: #fff;

    --soldOutColor: #eb3434;
    
    --statusBarColor: #fff;
    
    --primaryGradientColor: #00b970;
    --secondaryGradinetColor: #00b9a7;
   
    --overlayColor: #b90039;
    
    --primaryText: #010203;
    --secondaryText: #5d5d5d;
    --disabledText: rgba(0; 0; 0; 0.38);
   
    --background: #fff;
    --onBackground: #212121;
    --surface: #fff;
    --onSurface: #757575;
    --error: #cd040b;
    --onError: #fff;
    --black: #010203;
    --white: #fff;
}
  