.carousel-container {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column; 
  justify-content: flex-start;
}

.image-carousel {   
  position: relative;  
  width: 100%;
  height: var(--carousel-height);
  object-fit: cover;
}

.color-overlay-carousel {
  height: var(--carousel-height);
}

@media screen and (max-width: 1024px){
.color-overlay-carousel {
  height: var(--carousel--medium-height);
}
}

.color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  opacity: 0.4;
}


.arrows-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  position: absolute;
  bottom: 15%;
  right: var(--horizontal-padding);
  color:  var(--white);
  z-index: 10; 
}

@media screen and (max-width: 1024px) {
  .arrows-container {
    right: 5%;
  }
}

@media screen and (max-width: 768px) {
  .arrows-container {
    width:77px;
  }
  .arrows-container svg {
    width: 18px;
  }
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: var(--accentColor); 
  cursor: pointer;
  user-select: none;
}

@media screen and (max-width: 768px) {
  .arrow {
    width:30px;
    height:30px;
  }
}

.carousel-text-wrapper {
  position: absolute;
  top: 0%;
  width: 100%;
  height: var(--carousel-height);
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0 var(--horizontal-padding);
  color: white; 
}

.carousel-text-1 {
  flex: 0.9;
  width: 20vw;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-size: var(--xlarge-font-size);
  font-weight: 600;
  margin-top:30px;
}

@media screen and (max-width: 768px){
  .carousel-text-1 {
    width:25vw;
    font-size:18px;
  }
}
  
  
@media screen and (max-width: 600px){
  .carousel-text-1 {width:40vw;}
}

.carousel-text-separator {
  flex: 0.2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
 
}

.carousel-text-separator-element {
   border-top: 4px solid var(--main-red-aux);
    width: 10vw;
}

.carousel-text-2-wrapper {  
  flex: 0.9;
  display: flex;
  flex-direction: column;
}
.carousel-text-2 {
  width: 40vw;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: var(--medium-font-size);
  font-weight: 500;  
}


@media screen and (max-width: 768px){
  .carousel-text-2 {
    font-size:14px;
  }
}

.slide {
  position: relative;
  opacity: 0;
  transition: opacity 3s ease;
}

.slide.active {
  opacity: 1;
  transition: opacity 2s ease;
}

.left-arrow { 
  margin-left: -250px;
}

.right-arrow {
  margin-right: 250px;
}

.img-espa {
  position: absolute;
  width: 600px;
  height: 74px;
  top: 0;
  right: 0;
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .image-carousel, .carousel-text-wrapper {
    height: var(--carousel--medium-height);  
  }
}

@media screen and (max-width: 650px) {
  .image-carousel, .carousel-text-wrapper {
    min-height: 12vh;
  }  

  .carousel-text-2 {
    font-size: var(--small-font-size);
  }

  .img-espa {
    width: 330px;
    height: 35px;
    position: absolute;
    z-index: 1;
  }
}