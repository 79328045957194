@import "../src/theme/colors-blueberry.css";
@import "../src/theme/colors-jade.css";
@import "../src/theme/colors-mexican.css";
@import "../src/theme/layout.css";

html {
  box-sizing: border-box;  
}
*, *:before, *:after {
  box-sizing: inherit; 
}

.html-bold {
  font-family: 'Helvetica';
  font-weight: bold;
}
.html-greyscale{
  filter: grayscale(100%);
}
.html-high-contrast{
  filter: contrast(200%);
}
.html-negative-contrast{
  filter: invert(100%);
}

.html-font-size-0 * { 
  font-size: 100%; 
}

.html-font-size-1 * { 
  font-size: 110%; 
}

.html-font-size-2 * { 
  font-size: 120%; 
}

.html-font-size-3 * { 
  font-size: 130%; 
}

.html-font-size-minus-1 * { 
  font-size: 90%; 
}

.html-font-size-minus-2 * { 
  font-size: 80%; 
}

.html-font-size-minus-3 * { 
  font-size: 70%; 
}

.html-light-background {
  filter: brightness(200%);
}

.html-a-underline * span {
  text-decoration: underline;
}

body{
  padding:0px;
  margin: 0;
  width: 100%;
}

.body-container{
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
  align-items: center;
  width: 100%;  
}

.app-base {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
  align-items: center;
  width: 100%;  
}

.app-base-restaurant-closed {
  margin-top: 2.6vw;
}

@media screen and (max-width: 600px) { 
  .app-base-restaurant-closed {
    margin-top: 4.0vw;
  }
}

@media screen and (max-width: 450px) { 
  .app-base-restaurant-closed {
    margin-top: 7.0vw;
  }
}

@media screen and (max-width: 400px) { 
  .app-base-restaurant-closed {
    margin-top: 8.0vw;
  }
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.ml-2 {
  margin-left: 2vw;
}
.ml-3 {
  margin-left: 3vw;
}

.mt-1 {
  margin-top: 1vw;
}

.mt-2 {
  margin-top: 2vw;
}

.column-flex-center{  
  display: flex;   
  align-items: center;   
  justify-content: center;   
  flex-direction: column;
}

.column-flex-center-100{  
  display: flex;   
  align-items: center;   
  justify-content: center;   
  flex-direction: column; 
  width: 100%;
}

.column-flex-start-wrap{
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
  flex-wrap: wrap;
}

.column-flex-start{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; 
  flex-wrap: wrap;
}

.counter-container {
  margin: 0 0 2vh 2vw;
}


.row-flex-start-wrap{
    display: flex; 
    align-items: center;  
    justify-content: flex-start;
    flex-wrap:wrap
}

.row-flex-end-wrap{
  display: flex; 
  align-items: center;  
  justify-content: flex-end;
  flex-wrap:wrap
}

.row-flex-start-start-wrap{
  display: flex; 
  align-items: flex-start;  
  justify-content: flex-start;
  flex-wrap:wrap
}

.row-flex-start-no-wrap{
  display: flex; 
  align-items: center;  
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.row-flex-between-wrap{  
  display: flex;   
  align-items: center;    
  justify-content: space-between;
  flex-wrap:wrap
}

.row-flex-center{ 
  display: flex;    
  align-items: center;
  justify-content: center;
  flex-wrap:wrap;
}

.top-bar, .copyright-bar {
  display: flex;
  justify-content: space-between;
}

.top-bar-el, .copyright-bar-el {
  flex: 1;
  display: flex;
  align-items: center;
}

.copyright-bar-el {
  text-decoration:none;
}

.copyright-bar-el span{
  color:black;
}

span.top-bar-el-first, span.copyright-bar-el-first {
  display: flex;
  align-items: center;
}


.search-box{
  width: auto;
  background-color:  var(--white);
}

.terms-title{
  font-weight: bold;
  color: var(--settings-text);
  font-size: calc(1.5em + 0.8vw);
  letter-spacing: 1px;
  text-align:center;
  padding: 5vh var(--horizontal-padding);
}

.qr-code-container {
  width: 100%; 
  background-image: linear-gradient(45deg, rgb(133,16,16), rgb(227,30,36));
  color: var(--white-theme); 
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2vw var(--horizontal-padding);
  position: relative;
}

.arrow-down {
  display: block;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid var(--white);
  position: absolute;
  top: 0;
  left: 50%;
}

.qr-codes-wrap {
  display: flex;  
}

.qr-component {
  margin-top: 4vw;
}

.hanging-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.qr-hanging-image {
  width: 80px;
  height: 80px;
  border: 10px var(--white) solid;
  border-radius: 6px;
}

.qr-row {
  display: flex;
}

.hanging-line {
  height: 3px;  
  background-color: var(--white);
  padding: 3px;  
  width: 44px;
}

.left-rotate {
  position: absolute;
  top: -20px;
  left: 7px;
  -ms-transform: rotate(120deg);
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
}

.right-rotate {
  position: absolute;
  top: -20px;
  left: 32px;
  -ms-transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}

.dot {
  position: absolute;
  top: -45px;
  left: 32px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--white);
}

.qr-codes-wrap .section {
 flex: 1;
}

.section-2 {
 height: 12vw;
  width: 100%; 
}

.text-1 { 
  font-size: var(--large-font-size);
  font-weight: 500;
  width: 60%;
}

.line-20 {
  border-top: 6px solid var(--white-theme); 
  width: 20%;
  margin: 1vw 0;
}


.line-45 {
  border-top: 1px solid #9d9d9d; 
  width: 45%;
  margin: 4vw 0 2vw;
}


.text-2 {
  font-size: var(--small-font-size);
  font-weight: 400;
}

.scan-desk {
  display: block; 
  font-weight: 400;
  font-size: var(--small-font-size);  
  text-align: center;
  margin-top: 1vw;
}

.store-icons {
  display: none;
}



@media (max-width: 924px) {
 
  .qr-codes-wrap {
    display: none;
  }

  .arrow-down{
    display: none
  }  
  
  .header-mobile {
    width: 100%;
    text-align: center;
  }
  .store-icons{
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;   
  }

  .android-icons {    
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;   
  }
  .android-icons h5 {
    margin-bottom: 1vh;
    font-size: 20px;
    font-weight: 600;
  }

  .ios-icons {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
  }
  .ios-icons h5 {
    margin-bottom: 1vh;
    font-size: 20px;
    font-weight: 600;
  }

  .qr-code-text h3 {
    font-size: 1.9em;
  } 
}

.option-item:hover {
  background-color: #007bff;
  color:  var(--white);
  cursor: pointer;
}

label {
  color: black;
}

.header-sub {
  flex:1
}

.body {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  height: auto;
}

.body-100 {
  display: flex;
  flex-direction: column;
  background-color:var(--white);
  height: auto;
  width: 100%;
  padding: 2vh 0 2vh 0;
}

.menuComponent {
  display: flex;  
  flex-direction: column; 
  align-items: center;
  width: 110px;  
  border-radius: 10px; 
  cursor: pointer;
  margin: 0 1.4vw 2px 0px;
  transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
              0 2px 2px 0 rgba(0, 0, 0, 0.14),
              0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

@media (max-width: 600px) {
  .menuComponent {
    width: 90px;
    margin: 0 2vw 2px 0px;
  }
}

.menuComponent:hover {
  opacity: 0.8;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
}

.menu-component-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100px;
  border-top-left-radius: 10px; 
  border-top-right-radius: 10px;
}

@media (max-width: 600px) {
  .menu-component-image {
    height: 90px;
  }
}

.menuComponentText {  
  border-top: 1px solid var(--dark-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: -1px;
  height: 40px;  
  background-color: var(--white);
  width: 100%;
  padding: 1px;
  color: var(--black); 
  font-size: var(--small-font-size);
  line-height: calc(var(--small-font-size) + 1px);
  font-weight: 600;
  border-bottom-left-radius: 10px; 
  border-bottom-right-radius: 10px;
  line-height: 16px;
}

.title-container{  
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0.5vw var(--horizontal-padding); 
  width: 100%;
  line-height: 17px;
}

.reviews-title-container{  
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 1vw 0; 
  width: 100%;
}

.related-title-container {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  padding-left: var(--horizontal-padding);
  padding-right: var(--horizontal-padding);
  margin-top:15px;
}

.title-text{
  font-size: var(--large-font-size);
  font-weight: 700;
  color: var(--main-text-color); 
  white-space: nowrap;
}

.title-image-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  width: 3vw;
  margin: 0 1vw;
}

.title-image{
  width: 3vw;
}

@media (max-width: 768px) {
.title-image {
  width: 30px;
  margin-left: 30px;
}
}

.line-100 {
  border-top: 2px solid #9d9d9d;
  align-self:flex-end;
  width: 100%;
  margin-left:15px;
}

.container {
  padding: 0vw var(--horizontal-padding);
}

.after-title {
  border-top: 2px solid #9d9d9d;
  width: 100%;
}

.all-title-button { 
  background-color: var(--main-red-aux);
  color: var(--white);
  padding: 2px 10px;
  border-radius: 4px;
  position: absolute;
  top: 1.4vw;
  right: var(--horizontal-padding);
}

.all-title-button button {
  font-family: 'Geologica';
}

.all-title-button:hover { 
  background-color:rgb(0, 100, 52);
}

.all-title-text {
  font-size: var(--smedium-font-size);
  font-weight: 500;
}

.offers {
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  background-color: var(--background);
  width: 100%;
  padding: 2vw var(--horizontal-padding); 
}

@media (max-width: 768px) {
  .offers {
  padding: 5vw var(--horizontal-padding); 
  }
}

.product-card { 
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 16.9%;
  margin: 0 1.2vw 1.2vw 1.2vw;
  cursor: pointer;
  overflow: hidden;
  transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1); 
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
              0 2px 2px 0 rgba(0, 0, 0, 0.14),
              0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

@media screen and (max-width: 1250px){
  .product-card {
    width: 21.9%;
  }
}

@media screen and (max-width: 992px){
  .product-card {
    width: 30%;
    margin: 0 1.2vw 5vw 1.2vw;
  }
}

@media screen and (max-width: 650px){
  .product-card {
    width: 47%;
  }
}

@media screen and (max-width: 360px){
  .product-card {
    width:100%;
  };
}

.features-title {
  font-size: var(--smedium-font-size);
  font-weight: 600;
  margin: 1vw 0;
}

@media screen and (max-width: 768px){
  .features-title {
    font-size: var(--large-font-size);
    font-weight: 600;
    margin: 2vw 0;
  } 
  .all-title-text {
    font-size: var(--medium-font-size);
  }
}

.product-card:hover { 
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4); 
}

.product-card .offer-view .cardPic {
  transition: transform .5s ease;
}

.product-card:hover .offer-view .cardPic {
  transform: scale(1.05);
}


.cardPic { 
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 16vh;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@media screen and (max-width: 768px){
  .cardPic { 
    height: 14vh;
  }
}

@media screen and (max-width: 600px){
  .cardPic { 
    height: 12vh;
  }
}

.cardInfo {
  display: flex;
  background-color: var(--background);
  height: 16vh;
  padding-top: 5px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding:0 10px;
}

@media screen and (max-width: 768px){
  .cardInfo {
    height: 14.5vh;
}
}

.product-title-outter{
  min-height:57px;
}

.product-title-text {
  font-size: var(--medium-font-size);
  display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  margin:5px 0px 0px 0px;
}

@media (min-width: 1500px) {
  .product-title-text {
    font-size: var(--smallplus-font-size);
  }
}

@media (min-width: 1200px) {
  .product-title-text {
    font-size: var(--smedium-font-size);
  }
}

.card-price-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 95%;
  justify-content: space-between;
  margin-top: auto;
}

.price-field {
  font-size:var(--large-font-size);
  font-weight: 600; 
  color: var(--main-red-aux)
}

.erased-price {
  font-size: var(--medium-font-size);
  font-weight: 600; 
  color: var(--grey-dark)
}

.cardButton {
  display: flex;
  background-color: var(--accentColor);
  height: 5vh;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  align-items: center;
  justify-content: center;
  outline: none !important;
  border: none;
  cursor: pointer;
  margin-top:5px;
}

.cardButton-disabled {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
}

.cardButton-disabled:hover {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12)!important;
}

.cardButton:hover {
  background-color: rgb(0, 100, 52);
}

.cardButton-added {
  display: flex;
  background-color: #f37a7e;
  height: 5vh;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  align-items: center;
  justify-content: center;
  outline: none !important;
  border: none;
  cursor: default;
  margin-top:5px;
}

.cart-button-text {
  color: var(--white);
  font-size: var(--medium-font-size);
  font-family: 'Geologica';
}

.preFooter {
  display: flex;
}

.mainMenu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 10px var(--horizontal-padding);
  min-height: 15vh;
  background-color: #ffffff;
  position: relative;  
  margin: 12px 0px 9px 0px;
}

.category-pic-container {
  display: flex; 
  align-items: center;
  justify-content: flex-start;
  background-image: url('./img/Bgrnd-BringFood.jpg');
  background-position: center;
  background-repeat: repeat;
  resize: both;
  /*background-size: cover;*/
  width: 100%;
  height: 245px; 
  padding: 10px var(--horizontal-padding);
}

@media (max-width: 768px) {
  .category-pic-container {
    height: 180px; 
  }
}

@media (max-width: 600px) {
  .category-pic-container {
    height: 120px; 
  }
}

.cat-name-section {
  width: 60vw;
  height: 16vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--white);
  font-size: var(--xlarge-font-size);
  font-weight: 650;
}

.cat-image {
  object-fit: cover;
  width: 45vw;
  height: 245px;
  border-radius: 0px 0px 0px 50px;
  border-left: 5px solid var(--main-red-aux);
  position:absolute;
  right:0;
}

@media (max-width: 768px) {
  .cat-image {
    height: 180px; 
  }
}

@media (max-width: 600px) {
  .cat-image {
    height: 120px; 
  }
}

.sub-cat-container {
  background-color: var(--footer);
  background-image: none;
  height: 8vw;
  width: 100%;
  display: flex;
}

@media (max-width: 600px) {
  .sub-cat-container{
    height: 11vw;
  }
}

.sub-cat-container:hover {
  background-color: #cfcfcf;
}

.sub-cat-section {
  align-items: center;
  color: var(--black);
  display: flex;
  font-size: var(--large-font-size);
  font-weight: 700;
  height: auto;
  padding-left: 5vh;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
}

.sub-cat-image {
  width: 20vw;
  height: 8vw;
  object-fit: cover;
}

@media (max-width: 600px) {
  .sub-cat-image{
    height: 11vw;
    width: 25vw;
  }

}

.categoryView {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: var(--white);
  align-items: center; 
  width:  100%;
  justify-content: flex-start;
  padding: 15px 0; 
}

@media (max-width: 850px) {
  .categoryView {
    width: 100%;
  }
  .categoryView p {
    font-size: 14px;
  }
}

.categoryView p {
  border: 1px solid rgb(206 206 206);
  width: 95%;
  border-radius: 10px;
  padding: 20px 25px;
  box-shadow: 0px 0px 15px 0px rgba(125,104,107,0.53);
  -webkit-box-shadow: 0px 0px 15px 0px rgba(125,104,107,0.53); 
}

.subCategory{
  display: flex;
  height: 100%;
  width:  100%;
  flex-direction: column;
  align-items: normal;
  justify-content: center;
  padding: 1.4vw var(--horizontal-padding);
}

.categoryObjectText {
  display: flex;
  background-color: var(--background);
  height: 18vh;
  padding-top: 5px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding:0 10px;
}

@media (max-width: 768px) {
  .categoryObjectText {
    height:16vh;
  }
}

.description-text {
  padding: 14px 0 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical; 
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--smallplus-font-size)
}

@media (max-width: 500px) {
.description-text{
  font-size: 13px;
  padding: 4px 0 0px;
}
}

.category-title-text {
  font-size: var(--medium-font-size);
  font-weight: 600;
}

.categoryObjectButton {
  display: flex;
  height: 15%;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  outline: none !important;
}

.roundedButtonRed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--main-red-aux); 
  border: none; 
  color: #fff; 
  border-radius: 50%; 
  width: 35px; 
  height: 35px; 
  font-size: 24px;
  outline: none !important;
}

.roundedButtonRed:hover {
  background-color: rgb(0, 100, 52);
}

.roundedButtonGreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--accentColor); 
  border: none; 
  color: #fff; 
  border-radius: 50%; 
  width: 35px; 
  height: 35px; 
  font-size: 24px;
  outline: none !important;
}

.minus-button {
  margin-top: -5px;
}

.grey-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--footer);
  color: var(--black);
  font-size: var(--xlarge-font-size);
  font-weight: 650;
  width: 100%;
  height: var(--carousel-height);
  margin:0;
  padding: var(--horizontal-padding);
  text-align: center;
}

@media screen and (max-width: 1024px) {
 .grey-header {
    height: var(--carousel--medium-height);
 }
}

.productDetailsView {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: var(--white);
  height: auto;
  align-items: center;
  justify-content: center;
  padding:5vh 0;
}

@media screen and (max-width: 1024px) {
  .productDetailsView {
    padding:2vh 0 5vh 0;
  }
 }

.productDetailsContainer {
  display: flex;
  flex-wrap: wrap;
  background-color: var(--white); 
  width: 100%;
  padding: 2vw var(--horizontal-padding)
}

.product-details-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
}

@media screen and (min-width: 901px) {
  .padd-left-2 {
    padding-left: 2vw;
  }
}

.productDetailsPic {
  position: relative;
  background-repeat: no-repeat;  
  background-size: cover; 
  background-position:center;
  width: 100%;
  height: calc(240px + 10vw); 
  border-radius: 12px;
}

.favorite-button {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  height: 40px;
  width: 40px;
  border-radius: 40px;  
  background-color: rgb(0, 143, 74);
}

.favorite-button:hover{
  background-color: #006434;
}

.productDetailsTitle {
  display: flex;
  flex-direction: row;
  align-items: normal;
  justify-content: space-between;
  white-space: wrap;
  width: 100%;
  margin-bottom: 1vw;
}

@media screen and (max-width: 616px) {
.productDetailsTitle {
  margin-top:8px;
}
}

.product-details-title {
  font-size: var(--large-font-size);
  font-weight: 650;
}

.product-price-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  width: 20%; 
}

.product-price-field {
  font-size: var(--medium-font-size);
  font-weight: 650; 
  color: var(--main-red-aux)
}

.product-erased-price {
  font-size: var(--medium-font-size);
  font-weight: 600; 
  color: var(--grey-dark);
  margin-left: 1vw;
}

.productDetailsDescription {
  display: flex;  
  width: 100%;
  font-size: var(--medium-font-size);
  color: var(--black);
  padding-bottom: 1vw;
  margin-bottom: 1vw;
  border-bottom: 1px solid #9d9d9d;
}

@media screen and (max-width: 768px){
  .productDetailsDescription {
    font-size: var(--large-font-size);
    border-bottom: 2px solid #9d9d9d;
    padding-bottom: 2vw;
  }
}

.productDetailsSizesContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; 
  flex-wrap: wrap;
}

@media screen and (max-width: 900px) {
  .productDetailsContainer {
    padding: 2vw 0;
  }
  .product-details-section {
    min-width: 300px;
    padding: 0.4vw var(--horizontal-padding);
  }
  .product-erased-price, .erased-price, .product-details-title {
    font-size: var(--xlarge-font-size);   
  }
  .product-title-text, .category-title-text,  .product-price-field, .price-field, .menuComponentText {
    font-size: 15px;  
    font-weight: 600; 
  }
  .title-text {
    font-size:18px;
  }
}

@media screen and (max-width: 600px) {
  .product-details-title {
    font-size: var(--xlarge-font-size);
  } 
  .product-erased-price, .erased-price {
    font-size: var(--xlarge-font-size);   
  }
  .category-title-text, .price-field,  .product-price-field {
    font-size: var(--xlarge-font-size);  
    font-weight: 600; 
  }
  .productDetailsPic{
    width: 100%;
  }  
  
}


@media screen and (max-width: 600px) {
  .menuComponentText {
    font-size: 14px;  
    font-weight: 500; 
  }
}

.attribute-caption{
  display: flex;
}

.red-star {
  padding-top: 4px;
  color: red;
  
}

.attribute-title {
  display: flex;
  align-items: center;
  font-size: var(--small-font-size);
  font-weight: 600;
  margin-right: 1vw;
}

.weighted-container {
  display: flex;
  justify-content: flex-start;
  align-items: center; 
  width: 100%;
  margin-bottom: 0.5vw;
}

.caption-for-picker{
  text-align: start;
  font-size: var(--smedium-font-size);
  margin: 0 1vw;
}

.caption-for-weighted{
  text-align: start;
  font-size: var(--smedium-font-size);
  font-weight: 600;
}

.weight-buttons {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;  
}

.quantity-number{
  display: flex;
  align-items: center;
  margin: 0 1.5vw;
  font-size: var(--medium-font-size);
  font-weight: 600;
  white-space: nowrap;
}

.quantity-weighted {
  margin: 0 0.5vw;
  font-size: var(--smedium-font-size);
}

.productDetailsMaterials {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
}

.productDetailsSellectionButtonActive {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 30px;
  padding: 10px;
  margin: 5px 10px 5px 0px;
  background-color: var(--accentColor);
  border-radius: 4px;
  border: none;
  color: #ffffff;
  outline: none !important;
  font-family: 'Geologica';
  font-size:14px;
}

.productDetailsSellectionButtonInactive {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 30px;
  padding: 10px;
  margin: 5px 10px 5px 0px;
  background-color: var(--footer);
  border-radius: 4px;
  border: none;
  outline: none !important;
  color: var(--black);
  font-family: 'Geologica';
  font-size:14px;
}

@media screen and (max-width: 768px) {
  .productDetailsSellectionButtonActive, .productDetailsSellectionButtonInactive {
    font-size:12px;
  }
}

.product-details-textarea {
  border: none;
  width: 100%;
  background-color: var(--footer);
  font-size: var(--small-font-size);
  margin: 1vw 0;
  border-radius: 10px;
  padding: 15px;
  font-family: 'Geologica';
}

@media screen and (max-width: 768px) {
  .product-details-textarea {
    margin: 3vw 0;
  }
}

.productAddtocartButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 60%;
  height:auto;
  padding: 10px;
  background-color: var(--accentColor);
  border-radius: 25px;
  border: none;
  color: #ffffff;
  outline: none !important;
  /*box-shadow: 0 12px 20px rgba(0,0,0,0.25), 0 2px 2px rgba(0,0,0,0.22);*/
}

.productAddtocartButton:hover {
  background-color: rgb(0, 100, 52);
}

@media screen and (max-width: 600px) {
.productAddtocartButton {
  width:100%;
}
}

.productAddtocartButton:active {
  background-color: #2c5f2d;
  box-shadow: 0 2px #666;
  transform: translateY(2px);
}

.productAddtocartButton:disabled {
  background-color: #aaaaaa;
  box-shadow: 0 2px rgb(177, 177, 177);
  transform: translateY(2px);
}

.removeMaterialButton {
  background-color: #e4e6e6;
  border: none;
}

.product-attribute-quantity {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 1vw;
}

.attribute-quantity-button{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--accentColor); 
  border: none; 
  color: #fff; 
  border-radius: 50%; 
  width: 24px;
  height: 24px;
  font-size: 14px; 
  font-weight: 600;
}

.attribute-quantity-button-disabled{
  opacity: 0.6;
}

.attribute-quantity-number{
  display: flex;
  align-items: center;
  margin: 0 0.6vw;
  font-size: var(--smedium-font-size);
  font-weight: 600;
  white-space: nowrap;
}

.productDetailsQuantity {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 1vw 0;
}

.productDetailsButton {
  display: flex;
  height: 20%;
  width: 100%;
  margin-top: 20px;
  align-items: center;
  justify-content: flex-start;
  outline: none !important;
}

.modalContainer { 
  display: flex;  
  flex-direction: column;  
  align-items: center; 
  justify-content: center; 
  position: relative;
}

.modalTitleContainer { 
  display: flex; 
  flex-direction: column;  
  align-items: center;
  width: 100%;  
  justify-content: center; 
  font-size: var(--large-font-size);
  font-weight: 650;
}

.close-icon {
  position: absolute;
  top: 0px;
  right: 1vw;
  font-size: var(--large-font-size);
  font-weight: 650;
}

.close-icon:hover {
  color: var(--main-red);
}

.modalTitleCheckoutContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  justify-content: center;
  border-bottom: 2px solid #dfdfdf;
}

.modalFormContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2vw;
}

@media screen and (max-width: 768px) {
  .modalFormContainer {
    margin-top: 5vw;
  }
}

.reset-psw{
 text-decoration: underline;
 cursor: pointer;
}

.reset-psw:hover {
  color: var(--main-red);
}

.line-or {
  border-top: 1px solid var(--black);
  width: 6vw;
  margin-left: 1vw;
  margin-right: 1vw;
}

.login-or {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1vw 0;
  white-space: nowrap;
}

.modalFacebookContainer {  
  display: flex;    
  align-items: center;  
  justify-content:space-between;  
  flex-wrap: wrap;
  width: 100%;
}

@media screen and (max-width: 500px) {
  .modalFacebookContainer{ 
    justify-content: center;
  }
}

.my-facebook-button-class{
  display: flex; 
  width: 200px;
  height: 50px;
  flex-direction: row;
  align-items: center;  
  justify-content: center;
  background-color: var(--facebook-blue);
  color:  var(--white);
  font-size: 18px;
  font-weight: 650;
  border: 0px;
  border-radius: 50px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.6);
  margin-top: 1vw;
}

.my-facebook-button-class:hover {
  background-color: hsl(var(--facebook-blue-h), var(--facebook-blue-s), calc(var(--facebook-blue-l)*0.8));
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
}

.my-google-button-class{
  background-color: var(--main-red);
}

.my-google-button-class:hover {
  background-color: hsl(var(--main-red-h), var(--main-red-s), calc(var(--main-red-l)*0.8));
}

.google-text{
  margin-left: 4px;
}

.my-email-button-class{
  background-color: var(--accentColor);
  width: 100%;
}

.my-email-button-class:disabled {
  background-color: hsl(calc(var(--accentColor-h)), calc(var(--accentColor-s)*0.4), calc(var(--accentColor-l)*1.8));
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.6);
}

.my-email-button-class:hover:not(.my-email-button-class:disabled) {
  background-color: hsl(var(--accentColor-h), var(--accentColor-s), calc(var(--accentColor-l)*0.8));
}

.input {
  width: 50px;
}

.modalRegisterFormContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 70%;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}

.terms-login-text {
  font-size: 12px;
  text-align: center;
  margin: 2vw;
}

.terms-login-text a {
  color: var(--black);
}

.terms-login-text a:hover {
  color: var(--main-red);
}

.modalExitButtonContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 90%;
  align-items: center;
  padding: 10px;
  justify-content: space-evenly;
}

.modalVerificationCodeContainer {
  display: flex;  
  min-height: 33vh;
  width: 70%;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}

.modalVerificationCodeBox {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center; 
  justify-content: center;
  background-color: #cde6dd;
  border: 1px solid var(--button-default);
  border-radius: 4px;  
  text-align: center;
  font-size: 20px;
}

.cartCardContainer {
  padding: 10px;  
  height: 450px;
  width: 100%; 
  overflow: auto; 
  align-items: baseline; 
  background-color: #f1f1f1; 
  justify-content: center; 
  display: flex; 
  flex-direction: row;
}


.cartSidebar {
  width: 200px; 
  height: auto; 
  margin-left: 20px; 
  box-shadow: 0 2px 12px rgba(0,0,0,0.25), 0 2px 2px rgba(0,0,0,0.22);
  display: block;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #ffffff;
  align-self: flex-start;
}

.confirmationModalButtonsView {
  display: flex;
  align-items: center;
  justify-content: space-between; 
}

.change-password {  
  display: flex;  
  align-items: center; 
  justify-content: center;  
  width: 100%;
  height: 40px;
  border-width: 1px;
  border-color: grey;
  border-style: solid;
  background-color: lightgray;  
  justify-content: space-between;
  padding-left: 2vh;
}

.change-password button {
  outline: none;
  border: none; 
  display: flex;
  background-color: lightgray;
}

.change-pass-container { 
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row-reverse;
}

.upload-image {
  border: 1px solid var(--image-hover);
  background-color: #fdf3e3;
  border-radius: 4px; 
  padding: 5px 10px; 
  cursor: pointer;
  transition: 0.3s;
}

.upload-image:hover {
  background-color: var(--image-hover);
}

.settingsField {
  width: 400px; 
  height: 100px;
  box-shadow: 0 2px 12px rgba(0,0,0,0.25), 0 2px 2px rgba(0,0,0,0.22);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background-color: #ffffff;
  margin-top: 1px;
}

.passwordPic {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  background-image: url('./img/pass.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 80px;
  height: 80px;
}

.settingsFieldTitle {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.settingsIcon {
  margin-left: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.settingsArrowButton {
  border: none; 
  background-color: #fff; 
  outline: none !important;
}

.settingsArrowButton:hover {
  border: none; 
  background-color: rgb(231, 231, 231); 
  outline: none !important;
  border-radius: 15px;
  opacity: 1;
}

.closeButton {
  border: none; 
  background-color: #fff; 
  outline: none !important;
  align-self: flex-end;
}

.closeButton:hover {
  border: none; 
  background-color: rgb(231, 231, 231); 
  outline: none !important;
  border-radius: 15px;
  opacity: 1;
}

.addressAddContainer {
  width: 90%; 
  height: 300px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 10px;
  flex-direction: column;
}

.changeProfilePicContainer {
  width: 30px; 
  height: 30px; 
  background-color: #ffffff; 
  align-items: center; 
  justify-content: center; 
  border-radius: 15px;  
  margin-top: -35px;
  padding-top: 2px;
}

.changeProfilePic {
  width: 24px; 
  height: 24px; 
  background-color: #1fc281; 
  align-items: baseline; 
  justify-content: center;
  padding-left: 4px;
  padding-top: 4px; 
  border-radius: 15px;  
  border: none;
  outline: none !important;
}

.addressAddButton {
  border: none; 
  margin-top: 15px;
  outline: none !important;
  align-items: center;
  border-radius: 50px;
  width: 55px;
  height: 55px;
  box-shadow: 0 2px 12px rgba(0,0,0,0.25), 0 2px 2px rgba(0,0,0,0.22);
}

.addAddressOptions {
  width: 300px;
  height: 150px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.addAddressOptionsButton {
  width: 300px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

.addAddressOptionsButton-label {
  margin-right: 20px;
  background-color: cornsilk;
  width: auto;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 2px 12px rgba(0,0,0,0.25), 0 2px 2px rgba(0,0,0,0.22);
}

.addNewAddressButtonsContainer {
  width: 90%;
  height: auto;
  display: flex;  
  align-items: center;
  justify-content: space-evenly;
}

.typeAddressButton {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageIcon {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  width: 100%; 
}

.deleteButton {
  align-self: flex-end; 
  outline: none !important;
  border: none;
  background-color: #fff;
}

.resetPasswordModal {
  width: 100%; 
  height: 100%;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.newLabelContainer {  
  position: absolute;
  top: 0px;
  left: 55%;
  z-index:2;
  padding: 4px 8px;  
  background-color:  var(--new-label-color);
}

.discountLabelContainer {  
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 12px;
  padding: 4px 8px;
  z-index:2;
  background-color: var(--discount-label-color);
}

.hotLabelContainer {  
  position: absolute;
  top: 0px;
  right: 0px;
  border-top-right-radius: 12px;
  padding: 4px 5px 0px;
  z-index:2;
  background-color: var(--hot-label-color);
}

.soldOutLabelContainer {  
  position: absolute;
  top: 5vh;
  left: 20px;
  z-index:2;
  border-bottom-left-radius: 4;
  padding: 4px 8px;
  background-color: var(--sold-out-label-color);
}

.label {
  font-size: var( --smallplus-font-size);
  font-weight: 600;
  color: var(--onPrimaryColor);
}

.label-detail {
  font-size: 16;
  font-weight: 600;
  color: var(--onPrimaryColor);
}

.circular {
  width: 100%;
  height: 100%; 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  padding-top: 50px;
}

.cookie-updated-alert {
  position: fixed;
  z-index:12;
}

/*.delete-position {
  left: calc(50% - 200px); 
}*/

.small-alert{  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 400px; 
  border: 2px solid #9d9d9d;
  border-radius: 10px;
  padding: 10px;
  background-color: var(--white);
}

.voucher-alert{  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 2px solid #9d9d9d;
  border-radius: 10px;
  padding: 10px;
  background-color: var(--image-hover);
}

.loyalty-alert-container{
  z-index: 1100 !important;
  position: fixed;
  top: 20vh;
  right: 20vw;
}

.loyalty-alert{  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 2px solid #9d9d9d;
  border-radius: 10px;
  padding: 10px;
  background-color: var(--facebook-blue);
}

.copy-title-text {
  display: flex;
  font-size: var(--medium-font-size);
  align-items: center;
  font-weight: 650;
  white-space: nowrap;
  margin-right: 80px;
}

.copy-row{
  display: flex;
  align-items: center;
}

.copy-check-icon {
  margin-left: 1vw;
  font-size: var(--smedium-font-size);
  font-weight: 650;
}

.alert-title-text {
  display: flex;
  font-size: var(--medium-font-size);
  align-items: center;
  font-weight: 650;
  white-space: nowrap;
}

.delete-text {
  margin: 1vw 0;
}

.alert-body-text {
  font-size: var(--small-font-size);
  font-weight: 600;
  margin-top:4px;
}

.go-cart {
  font-size: var(--small-font-size);
  font-weight: 600;
  color: var(--main-red-aux);
  margin: 1vw 0 0.5vw;
  text-align: center;
  cursor: pointer;
}

.go-cart:hover {
  opacity: 0.6;
}

.alert-close-icon {
  position: absolute;
  top: 1vw;
  right: 1vw;
  font-size: var(--large-font-size);
  font-weight: 650;
}

.alert-close-icon:hover {
  color: var(--main-red);
}

.delete-alert  {
  align-items: center;
  width: 45%;
}

.delete-product-alert  {
  align-items: center;
  width: 500px;
}

.logout-container {
 margin-top: 2vw;
}

@media screen and (max-width: 767.98px) {
  .small-alert{
    width: 60vw;
  }
 
}

.cookie-alert-container{
  z-index: 1100 !important;
  position: fixed;
  top: 40vh;
  right: 20vw;
}

.cookie-alert{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 60vw; 
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  background-color: var(--white);
}

.cookie-language-button {
  position: absolute;
  top: 1vw;
  right: 1vw;
  color: var(--grey-dark);
}

.cookie-body-container {
  display: flex;
}

.cookie-body-text {
  display: flex;
  flex: 3;
  justify-content: flex-start;
  font-size: var(--xsmall-font-size);
  font-weight: 600;
}

.cookie-buttons-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.cookie-buttons {
  display: flex;
  justify-content: flex-end;
}

.cookie-button {
  display: flex; 
  flex-direction: row;
  align-items: center;  
  justify-content: center;
  background-color: var(--main-red);
  color:  var(--white);
  font-size: var(--xsmall-font-size);  
  font-weight: 500;
  border: 0px;
  border-radius: 5vw;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.6);
  padding: 0 2vw;  
  margin-left: 0.5vw;
  height: 30px;
  font-family: 'Geologica';
}

.cookie-button:hover {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
  background-color: hsl(var(--main-red-h), var(--main-red-s), calc(var(--main-red-l)*0.8));
}

.cookie-privacy-button {
  background-color: hsl(var(--main-red-h), var(--main-red-s), calc(var(--main-red-l)*1.4));
}

.delete-button {
 padding: 0.8vw 4vw;
 margin-left: 0;
}

.delete-yes-button {
  background-color: var(--white);
  border: 1px solid var(--main-red);
  color: var(--main-red);
}

.delete-yes-button:hover {
  background-color: var(--footer);
}

.delete-buttons-container {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin: 2vw 0 1vw;
}

@media screen and (max-width: 1100px) {
  .cookie-alert-container{ 
    left: 10vw;
  }

  .cookie-alert{
    width: 80vw;
  }

  .cookie-button {
    height: 2vw;
  }
}

@media screen and (max-width: 768px) {
  .cookie-button {
    height: 4vw;
  }
}

.my-alert-enter {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.my-alert-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}

.my-alert-exit {
  opacity: 1;
}

.my-alert-exit-active {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}  

.accessibility-title{
  font-size: 18;
  font-weight: 700;
  text-align: center;
}

.aboutView {
  width:100%;
}

.html-text{      
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: var(--smedium-font-size);
  padding: 5vh var(--horizontal-padding);
  background-color: var(--background);
}

.html-text ul{
    list-style-type: disc; 
    padding-left: 2vw;   
}
.html-text p{   
    margin-bottom: 0;
}

.status-badge{
  padding: 1px 4px;
}

.pending{
  background-color: lightyellow;
}
.processing{
  background-color: yellow;
}
.completed{
  background-color: lightgreen;
}
.delivering{
  background-color: greenyellow;
}
.delivered{
  background-color: rgb(50, 186, 50);
}
.restaurant-closed-header {
  background-color: var(--main-red);
  min-height: 2.6vw;  
  display: flex; 
  flex-direction: row; 
  align-items: center;
  justify-content: center;
  font-size: var(--medium-font-size);
  color: white;
  width: 100%;
  flex-wrap: nowrap;
  position: relative;  
  text-align:center;
}

.restaurant-closed-header svg{
  width: 25px;
  margin-right: 5px;
}

@media screen and (max-width: 768px) {
.restaurant-closed-header svg{
  width: 15px;
}
}

.restaurant-closed-body-container{
  z-index: 10000;
  position: fixed;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;  
 }
 .restaurant-closed-text {
  display: flex; 
  flex-wrap: wrap;
 }
 .cancel-restaurant-message-icon {
    position: absolute;
    right: 0.5vw;  
    top:1px; 
 }

 @media screen and (max-width: 900px) {
  .restaurant-closed-header {
    font-size: var(--small-font-size);
  } 
}

 .cursor-pointer {
  cursor: pointer;
}

.product-evaluation-container {    
  display: flex;     
  flex-direction: column;
  align-items: center;    
  justify-content: center;
 width: 100%;
  min-height: 30vh;
  padding-top: 0vh;
  padding-left: var(--horizontal-padding);
  padding-right: var(--horizontal-padding);
  margin-top: 2vh;
  margin-bottom: 5vh;
}

@media screen and (max-width: 900px) {
  .product-evaluation-container {  
  margin-bottom: 0vh;
  min-height:22vh;
  }
}

.product-evaluation-body {    
  display: flex;    
  flex-direction: column;    
  align-items: flex-start;    
  justify-content: flex-start;
  width: 100%;
  height: auto;
  margin-top: 5vh;
  font-size: calc(10px + 0.5vw);
}

.product-evaluation-body p {
  margin: 5px 0;
}

.product-evaluation-body Textarea {
  width: 100%;
  margin-bottom: 10px;
}

.product-evaluation-title {      
  display: flex;      
  flex-direction: row;     
  align-items: center;     
  justify-content: flex-start;
  width: 100%;
  height: 3vh;
  font-weight: bold;
  border-bottom-width: 1px;
  border-bottom-color: black;
  border-bottom-style: solid;
  padding: 5px;
  margin-top: 5vh;
}

.rated-card {
  width: 100%;
  height: auto;
  border-width: 0px;
  border-style: solid;
  border-color: grey;
  padding: 10px;
  background-color: var(--off-white);
  margin-bottom: 15px;
  border-radius: 5px;
}

.rated-card .rated-card-title {
  width: 100%;
  height: 6vh;    
  display: flex;    
  flex-direction: row;   
  align-items: flex-start;
}

.rated-card .rated-card-commentText {    
  display: flex;    
  flex-direction: row;    
  align-items: flex-start;
  width: auto;
  height: auto;
}

.rated-card .rated-card-commentText textarea {
  border: none;
  background-color: var(--off-white);
  resize: none;
}

.no-category-items {
  color: gray;
  font-size:  var(--large-font-size); 
  font-weight: bold; 
  margin-top: 2vh;
}

