/* geologica-regular - greek_latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Geologica';
    font-style: normal;
    src: url('./fonts/geologica/geologica-v1-greek_latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }

:root {
    --horizontal-padding: 10vw;    
    --xsmall-font-size: calc(9px + 0.3vw);
    --small-font-size: calc(10px + 0.3vw);
    --smallplus-font-size: calc(11px + 0.3vw);
    --smedium-font-size:  calc(9px + 0.6vw);
    --medium-font-size:  calc(10px + 0.7vw);
    --large-font-size:  calc(10px + 0.9vw);
    --xlarge-font-size:  calc(11px + 1.2vw);
    --carousel-height: 450px;
    --carousel--medium-height: 30vh;
}

body {
    margin: 0;
    font-family:  "Geologica", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

@media screen and (max-width: 768px) {
    :root {
        --horizontal-padding: 2vw;
    }
}

@media screen and (max-width: 992px) {
    :root {
        --horizontal-padding: 4vw;
    }
}


