 .reservation-container { 
  width: 100%; 
  display: flex;  
  margin: 3vw 0; 
  flex-wrap: wrap;  
  padding: 0 var(--horizontal-padding);
}

.reservation-body{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 6;
}

.reservation-section-header {
  display: flex;
  align-items: baseline;
  width: 100%;
  font-size: var(--large-font-size);
  font-weight: 650;
  margin: 1vw 0;
}

.reservation-section-line {
  display: flex;
  flex: 1;
  border-bottom: 1px solid var(--black);
  margin-left: 2vw;
}


.reservation-total-table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 4;  
  padding-left: 4vw; 
}

@media screen and (max-width: 1250px){
  .reservation-container{
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .reservation-body{
    flex: 1;
  }
  .reservation-total-table {
    flex: 1;
    padding-left: 0vw;
  }
}

@media screen and (max-width: 768px){
  .reservation-container {
    padding: 0.5vw;
  }
  .reservation-total-table{
    padding: 0.4vw var(--horizontal-padding);
  }
  .reservation-body {
    padding: 0.4vw var(--horizontal-padding);
  }
} 
  
  .reservation-total-body {    
    display: flex;  
    flex-direction: column;
    padding-bottom: 1vw;    
    border-bottom: 1px solid var(--black);
    margin-bottom: 1vw;
  }

  .reservation-totals-line {
    display: flex;
    font-size: var(--smedium-font-size);
    font-weight: 600;
    margin-top: 1vw;
  }
  
 .reservation-total-footer {      
    display: flex; 
    flex-direction: column; 
    align-items: center;  
    justify-content: center; 
    padding: 3vw 1vw;    
  }

  .mandatory-red-star {
    color: red;    
  } 

  .reservation-button-container {
    display: flex;    
    flex-direction: row;    
    justify-content: flex-start;
    width: 100%;
    padding-left: var(--horizontal-padding);
    padding-right: var(--horizontal-padding);
  }
  
  .reservation-select-row {
    margin-top: 2vw;
    display: flex;
    justify-content: space-between;
  }
  
  .reservation-second-input {
    margin-left: 2vw;
  }

  .reservation-table-footer {  
    margin-top: 4vw;
  }
  